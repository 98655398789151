<template>
  <div class="bg-white rounded flex-col">
    <div class="flex justify-between items-center p-5">
      <div class="flex items-center space-x-2">
        <img
          :src="getImageForService(serviceId) || undefined"
          class="w-4 h-5"
          alt="Service Image"
          loading="lazy"
        />
        <lf-h4 class="whitespace-nowrap">{{ serviceName }}</lf-h4>
      </div>
      <lf-switch
        :model-value="isActive"
        class="flex-grow-0"
        :name="serviceName"
        :disabled="isActiveTemplatePublished"
        @toggle-changed="updateActiveService"
      />
    </div>

    <div v-if="isActive" class="flex flex-col border-t p-5 space-y-6">
      <div v-if="details" class="flex flex-col space-y-6">
        <span class="text-headline font-medium">
          {{ $t("ORCHESTRATION.DATA_SERVICE_DETAIL") }}
        </span>
        <div class="px-1 space-y-3">
          <lf-checkbox
            v-if="canSelectMultiple"
            v-model="areAllDsdsSelected"
            data-cy="dsd-option-select-all"
            name="dsdSelectAll"
            value=""
            :disabled="isActiveTemplatePublished"
          >
            <span class="text-gray-400">
              {{ $t("COMMON.SELECT_ALL") }}
            </span>
          </lf-checkbox>
          <div
            v-for="(value, key) in Object.keys(details)"
            :key="key"
            class="flex items-center space-x-4 text-headline"
            :class="{
              'cursor-not-allowed opacity-50': isActiveTemplatePublished,
              'cursor-pointer': !isActiveTemplatePublished
            }"
          >
            <div v-if="canSelectMultiple">
              <lf-checkbox
                :id="`dsd-option-${key}`"
                v-model="activeDsds"
                :name="value"
                :value="value"
                :disabled="isActiveTemplatePublished"
              />
            </div>
            <div
              v-else
              class="flex items-center space-x-1-5"
              :class="{ '-ml-5-5': showWarningIcon(details[value]?.[0]) }"
            >
              <icon-base
                v-if="showWarningIcon(details[value]?.[0])"
                icon="warning"
                class="warning-icon"
                icon-color="orange"
              />
              <input
                :id="`dsd-option-${key}`"
                v-model="activeDsd"
                type="radio"
                name="dsd"
                class="form-radio cursor-pointer"
                :class="{
                  'cursor-not-allowed text-gray-400': isActiveTemplatePublished,
                  'cursor-pointer': !isActiveTemplatePublished
                }"
                :value="value"
                :disabled="isActiveTemplatePublished"
              />
            </div>
            <label
              :for="`dsd-option-${key}`"
              :class="{
                'cursor-not-allowed': isActiveTemplatePublished,
                'cursor-pointer': !isActiveTemplatePublished
              }"
            >
              {{ value }}
            </label>
          </div>
        </div>
      </div>
      <div
        v-if="hasSelected && repullOptionEnabled"
        class="flex flex-col space-y-4"
      >
        <div class="flex space-x-3 space-y-1">
          <lf-checkbox
            v-model="repullOptionSelected"
            name="re_pull_option"
            :value="repullOptionSelected"
            :disabled="isActiveTemplatePublished"
          >
            {{ $t("ORCHESTRATION.REPULL_DATA_MESSAGE") }}
          </lf-checkbox>
          <icon-base
            v-tooltip="$t('ORCHESTRATION.REPULL_DATA_TOOLTIP')"
            icon="info-small"
            :icon-color="UTIL_COLORS.DEFAULT"
            class="cursor-point"
          />
        </div>
        <div class="flex space-x-3">
          <lf-input
            class="h-10"
            name="refresh_ttl"
            type="number"
            :min="0"
            :value="refreshTtl"
            :disabled="isActiveTemplatePublished"
            no-margin
            @key-released="handleRefreshTtlValueChanged"
          />
          <lf-dropdown
            class="min-w-50 mr-4 max-h-10"
            name="refresh_ttl_period"
            :options="DO_TEMPLATE_REFRESH_TTL_PERIODS"
            :value="DO_TEMPLATE_REFRESH_TTL_PERIODS[refreshTtlPeriod]"
            :disabled="isActiveTemplatePublished"
            full-width
            @change="handleRefreshTtlPeriodChanged"
          />
        </div>
      </div>
      <div v-if="hasSelected && ocrolusSourceEnabled">
        <lf-dropdown
          class="min-w-50 max-h-10"
          name="ocrolus_book_source"
          :placeholder="$t('ORCHESTRATION.OCROLUS_BOOK_SOURCE')"
          :options="OCROLUS_BOOK_OPTIONS"
          :value="ocrolusBookOption"
          full-width
          @change="handleChangeBookSource"
        />
      </div>
      <div v-if="hasSelected && inscribeSourceEnabled">
        <lf-dropdown
          class="min-w-50 h-10"
          name="inscribe_number_of_bank_statements"
          :placeholder="$t('ORCHESTRATION.NUMBER_OF_BANK_STATEMENTS')"
          :options="inscribeBankStatementOptions"
          :value="inscribeBankStatementOption"
          full-width
          @change="handleChangeBankStatementOption"
        />
      </div>
      <div v-if="hasSelected && canIncludeInsights">
        <lf-checkbox
          v-model="includeInsights"
          name="include_insights"
          :value="includeInsights"
          :disabled="isActiveTemplatePublished"
          @change="updateIncludeInsightsValue"
        >
          {{ $t("DEALS.DEAL_PROGRESS.BANK_CONNECTION.INCLUDE_INSIGHTS") }}
        </lf-checkbox>
      </div>
      <div v-if="hasSelected && canSyncTransactions">
        <lf-checkbox
          v-model="syncTransactions"
          name="track_transaction_updates"
          :value="syncTransactions"
          :disabled="isActiveTemplatePublished"
          @change="updateSyncTransactionsValue"
        >
          {{ $t("DEALS.DEAL_PROGRESS.BANK_CONNECTION.SYNCED_DATA") }}
        </lf-checkbox>
      </div>
      <div v-if="hasSelected && isExperianQuickSearch">
        <lf-dropdown
          name="select_service_type"
          data-cy="experian-quick-search-source-selector"
          custom-button-height="h-10"
          :value="selectedQuickSearchOption"
          :options="searchOptions"
          full-width
          @change="handleChangeQuickSearchOption"
        />
      </div>
      <div v-if="hasSelected" class="flex flex-col space-y-3">
        <span
          v-if="!isActiveTemplatePublished"
          class="text-headline font-medium"
        >
          {{ $t("ORCHESTRATION.CHOOSE_ATTRIBUTES") }}
        </span>
        <multiselect-dropdown
          class="w-full"
          name="attributes"
          :placeholder="$t('ORCHESTRATION.ATTRIBUTES')"
          :value="selectedAttributes"
          :options="selectOptions"
          :is-absolute-positioned="false"
          scrollable-parent="do-service"
          :disabled="isActiveTemplatePublished"
          :allow-trim="false"
          @change="handleSave"
        />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import type { PropType } from "vue";
import { ref, computed, watch } from "vue";
import { useStore } from "vuex";
import { getImageForService } from "@/helpers/services";
import type {
  DataOrchestrationTemplateRePullPeriod,
  IAttribute,
  IStep,
  IStepAttribute
} from "@/models/orchestration";
import { useDoActiveTemplateStatus } from "@/hooks/orchestration";

import {
  DISABLED_SERVICES_FOR_DATA_RE_PULL,
  DO_TEMPLATE_REFRESH_TTL_PERIODS,
  DO_TEMPLATE_REFRESH_TTL_CONVERSIONS_FROM_HOURS,
  UTIL_COLORS,
  MONTHS
} from "@/helpers/constants";
import {
  OCROLUS_BOOK_OPTIONS,
  OCROLUS_BOOK_OPTION_BOTH
} from "@/helpers/constants/ocrolus";
import MultiselectDropdown from "@/components/ui/inputs/MultiselectDropdown.vue";
import LfCheckbox from "@/components/ui/inputs/LfCheckbox.vue";
import cloneDeep from "lodash/cloneDeep";
import has from "lodash/has";
import zipObject from "lodash/zipObject";
import map from "lodash/map";
import { getObjectKeys, looksLikeNumber } from "@/helpers/common";
import { searchOptions } from "@/helpers/constants/experian";
import { SearchOption } from "@/enums/experian";
import { OcrolusBookClass } from "@/enums/dataServices";

const emit = defineEmits(["updateActiveService"]);

const props = defineProps({
  serviceName: {
    type: String,
    default: ""
  },
  serviceId: {
    type: Number,
    default: -1
  },
  details: {
    type: Object as PropType<Record<string, IAttribute[]>>
  },
  isActive: {
    type: Boolean,
    default: false
  },
  serviceAttributes: {
    type: Array as PropType<IAttribute[]>
  },
  activeStep: {
    type: Object as PropType<IStep | null>,
    required: true
  }
});

const { commit, getters } = useStore();
const { isActiveTemplatePublished } = useDoActiveTemplateStatus();

const PLAID_TRANSACTIONS = "Plaid Transactions" as const;
const PLAID_ASSET_REPORT = "Plaid Asset Report" as const;
const OCROLUS = "Ocrolus" as const;
const INSCRIBE = "Inscribe" as const;
const defaultInscribeBankStatementOption = 3 as const;

const includeInsights = ref(false);
const syncTransactions = ref(false);

const selectedQuickSearchOption = ref<SearchOption>(SearchOption.BUSINESS_NAME);

const selectedAttributes = computed(() => props.activeStep?.attributes || []);
const canSelectMultiple = computed(() =>
  props.serviceName.toLowerCase().includes("transunion")
);

const isExperianQuickSearch = computed(
  () => props.serviceName === "Experian" && activeDsd.value === "Quick Search"
);

const canIncludeInsights = computed(
  () => activeDsd.value === PLAID_ASSET_REPORT
);

const canSyncTransactions = computed(
  () => activeDsd.value === PLAID_TRANSACTIONS
);

const repullOptionEnabled = computed(
  () => !DISABLED_SERVICES_FOR_DATA_RE_PULL.includes(props.serviceName)
);

const ocrolusSourceEnabled = computed(() => {
  return props.serviceName === OCROLUS;
});

const inscribeSourceEnabled = computed(() => {
  return props.serviceName === INSCRIBE;
});

const getSelectedModels = (singleModel = true): string[] => {
  const keys = Object.keys(props.details || {});

  if (!keys.length) {
    return [];
  }

  return singleModel
    ? [keys.find(findModelInAttributes) ?? ""]
    : keys.filter(findModelInAttributes) || [];
};

const findModelInAttributes = (key: string) =>
  selectedAttributes.value.some(
    (attr) => props.details?.[key]?.map((a) => a.id).includes(attr.id)
  );

const activeDsd = ref<string>(
  canSelectMultiple.value ? "" : getSelectedModels()[0]
);

const activeDsds = ref<string[]>(
  canSelectMultiple.value ? getSelectedModels(false) : []
);

const refreshTtlPeriod = ref<DataOrchestrationTemplateRePullPeriod>(
  Object.keys(
    DO_TEMPLATE_REFRESH_TTL_PERIODS
  )[0] as DataOrchestrationTemplateRePullPeriod
);

const inscribeBankStatementOption = ref<number>(
  defaultInscribeBankStatementOption
);
const inscribeBankStatementOptions = ref(
  zipObject(map(MONTHS, "monthNum"), map(MONTHS, "monthNum"))
);

const ocrolusBookOption = ref<keyof typeof OCROLUS_BOOK_OPTIONS>(
  OCROLUS_BOOK_OPTION_BOTH
);
const ocrolusBookClass = ref<OcrolusBookClass>(OcrolusBookClass.COMPLETE);

const extractRefreshTtl = () => {
  if (selectedAttributes.value.length) {
    return selectedAttributes.value[0].options?.refresh_ttl_hours;
  }

  // If there are no attributes for active step yet,
  // try to find the step with the same title within the template
  // and extract refresh TTL from there
  const steps = getters["orchestration/activeTemplateSteps"] as IStep[];
  const sameStepAsActiveOne = steps.find(
    (step) =>
      step.id !== props.activeStep?.id && step.title === props.activeStep?.title
  );

  return (
    sameStepAsActiveOne?.attributes?.[0]?.options?.refresh_ttl_hours ?? null
  );
};

const refreshTtl = ref(extractRefreshTtl());

const repullOptionSelected = ref(refreshTtl.value != null);

const areAllDsdsSelected = computed({
  get() {
    const all = getObjectKeys(props.details ?? {});
    return !!all.length && all.every((dsd) => activeDsds.value.includes(dsd));
  },
  set(value) {
    activeDsds.value = value ? Object.keys(props.details || {}) : [];
  }
});

const selectOptions = computed<IAttribute[]>(() => {
  if (activeDsd.value && props.details?.[activeDsd.value]) {
    return props.details[activeDsd.value];
  }

  if (activeDsds.value.length) {
    return activeDsds.value.flatMap((dsd) => props.details?.[dsd] || []);
  }

  if (props.serviceAttributes) {
    return props.serviceAttributes;
  }

  return [];
});

const hasSelected = computed(() => !!selectOptions.value.length);

const updateActiveService = () => {
  const payload = props.isActive ? "" : props.serviceName;
  emit("updateActiveService", payload);
  handleSave();
};

const showWarningIcon = (value: IAttribute | undefined) => {
  if (!value) {
    return false;
  }
  return [
    "experian_bop_commercial_collections",
    "experian_bop_commercial_collections_premier_attributes",
    "experian_bop_commercial_collections_pdf_report",
    "experian_bop_commercial_lending_to_a_sole_prop",
    "experian_bop_commercial_lending_to_a_sole_prop_premier_attributes",
    "experian_bop_commercial_lending_to_a_sole_prop_pdf_report"
  ].includes(value.service_id || "");
};

const handleSave = (attributes: IStepAttribute[] = []) => {
  const removedAttributes = selectedAttributes.value?.filter(
    (attribute) => !attributes.includes(attribute)
  );
  commit("orchestration/setStepAttributes", {
    stepId: props.activeStep?.id,
    attributes
  });

  updateRefreshTtl();

  if (ocrolusSourceEnabled.value) {
    updateOcrolusBookSource();
  } else if (canIncludeInsights.value) {
    updateIncludeInsightsValue();
  } else if (canSyncTransactions.value) {
    updateSyncTransactionsValue();
  } else if (inscribeSourceEnabled.value) {
    updateInscribeBankStatementsValue();
  } else if (isExperianQuickSearch.value) {
    updateQuickSearchOption();
  }

  if (removedAttributes.length) {
    commit("orchestration/removeAttributesFromOutcomes", {
      stepId: props.activeStep?.id,
      removedAttributes
    });
  }
};

const handleRefreshTtlValueChanged = (value: number) => {
  refreshTtl.value = value;
  updateRefreshTtl();
};

const handleRefreshTtlPeriodChanged = (
  period: DataOrchestrationTemplateRePullPeriod
) => {
  refreshTtlPeriod.value = period;
  updateRefreshTtl();
};

const handleChangeBookSource = (option: keyof typeof OCROLUS_BOOK_OPTIONS) => {
  ocrolusBookOption.value = option;
  updateOcrolusBookSource();
};

const handleChangeQuickSearchOption = (option: keyof typeof searchOptions) => {
  selectedQuickSearchOption.value = option;
  updateQuickSearchOption();
};

const handleChangeBankStatementOption = (option: number) => {
  inscribeBankStatementOption.value = option;
  updateInscribeBankStatementsValue();
};

const updateInscribeBankStatementsValue = () => {
  const attributes = cloneDeep(selectedAttributes.value);

  attributes.forEach((attribute) => {
    const numberOfBankStatements = looksLikeNumber(
      inscribeBankStatementOption.value
    )
      ? Number(inscribeBankStatementOption.value)
      : defaultInscribeBankStatementOption;
    attribute.options = {
      number_of_bank_statements: numberOfBankStatements
    };
  });

  commit("orchestration/setStepAttributes", {
    stepId: props.activeStep?.id,
    attributes
  });
};

const updateRefreshTtl = () => {
  const attributes = cloneDeep(selectedAttributes.value);

  const convertedRefreshTtl =
    refreshTtl.value != null && repullOptionSelected.value
      ? refreshTtl.value *
        DO_TEMPLATE_REFRESH_TTL_CONVERSIONS_FROM_HOURS[refreshTtlPeriod.value]
      : null;

  attributes.forEach((attribute) => {
    if (convertedRefreshTtl == null) {
      if (!attribute.options) {
        return;
      }

      delete attribute.options;
      return;
    }

    attribute.options = {
      refresh_ttl_hours: convertedRefreshTtl
    };
  });

  commit("orchestration/setStepAttributes", {
    stepId: props.activeStep?.id,
    attributes
  });
};

const getSavedOcrolusBookSource = (attributes: IStepAttribute[]) => {
  if (!attributes.length) {
    return;
  }
  const source = attributes[0].options?.ocrolus_book_source;
  const savedClass = attributes[0].options?.ocrolus_book_class;
  if (source) {
    if (savedClass === OcrolusBookClass.INSTANT) {
      ocrolusBookOption.value =
        `${source}_instant` as keyof typeof OCROLUS_BOOK_OPTIONS;
    } else {
      ocrolusBookOption.value = source as keyof typeof OCROLUS_BOOK_OPTIONS;
    }
  }
  if (savedClass) {
    ocrolusBookClass.value = savedClass as OcrolusBookClass;
  }
};

const getIncludeInsightsValue = (attributes: IStepAttribute[]) => {
  includeInsights.value = has(attributes[0]?.options, "include_insights")
    ? Boolean(attributes[0]?.options?.include_insights)
    : true;
};

const getSyncTransactionsValue = (attributes: IStepAttribute[]) => {
  syncTransactions.value = has(
    attributes[0]?.options,
    "track_transaction_updates"
  )
    ? Boolean(attributes[0]?.options?.track_transaction_updates)
    : true;
};

const getExperianQuickSearchSource = (attributes: IStepAttribute[]) => {
  selectedQuickSearchOption.value =
    attributes[0]?.options?.quick_search_source ||
    selectedQuickSearchOption.value ||
    SearchOption.BUSINESS_NAME;
};

const getBankStatementOptionValue = (attributes: IStepAttribute[]) => {
  inscribeBankStatementOption.value =
    attributes[0]?.options?.number_of_bank_statements ||
    defaultInscribeBankStatementOption;
};

const updateOcrolusBookSource = () => {
  const attributes = cloneDeep(selectedAttributes.value);

  attributes.forEach((attribute) => {
    if (ocrolusBookOption.value === "") {
      if (!attribute.options) {
        return;
      }

      delete attribute.options;
      return;
    }
    if (ocrolusBookOption.value.includes(OcrolusBookClass.INSTANT)) {
      attribute.options = {
        ocrolus_book_source: ocrolusBookOption.value.replace("_instant", ""),
        ocrolus_book_class: OcrolusBookClass.INSTANT
      };
      if (ocrolusBookOption.value === "both_instant") {
        delete attribute.options.ocrolus_book_source;
      }
    } else {
      attribute.options = {
        ocrolus_book_source: ocrolusBookOption.value,
        ocrolus_book_class: OcrolusBookClass.COMPLETE
      };
    }
  });

  commit("orchestration/setStepAttributes", {
    stepId: props.activeStep?.id,
    attributes
  });
};

const updateQuickSearchOption = () => {
  const attributes = cloneDeep(selectedAttributes.value);

  attributes.forEach((attribute) => {
    attribute.options = {
      quick_search_source: selectedQuickSearchOption.value
    };
  });

  commit("orchestration/setStepAttributes", {
    stepId: props.activeStep?.id,
    attributes
  });
};

const updateIncludeInsightsValue = () => {
  const attributes = cloneDeep(selectedAttributes.value);

  attributes.forEach((attribute) => {
    attribute.options = { include_insights: includeInsights.value };
  });

  commit("orchestration/setStepAttributes", {
    stepId: props.activeStep?.id,
    attributes
  });
};

const updateSyncTransactionsValue = () => {
  const attributes = cloneDeep(selectedAttributes.value).map((attribute) => ({
    ...attribute,
    options: { track_transaction_updates: syncTransactions.value }
  }));

  commit("orchestration/setStepAttributes", {
    stepId: props.activeStep?.id,
    attributes
  });
};

watch(repullOptionSelected, () => updateRefreshTtl());

watch([activeDsd, activeDsds], ([, newDsds]) => {
  const attributesToRetain = newDsds.flatMap((dsd) =>
    selectedAttributes.value.filter(
      (attribute) =>
        props.details?.[dsd]?.map((a) => a.id).includes(attribute.id)
    )
  );
  handleSave(attributesToRetain);
});

watch(
  selectedAttributes,
  (attributes) => {
    getSavedOcrolusBookSource(attributes);
    getIncludeInsightsValue(attributes);
    getSyncTransactionsValue(attributes);
    getBankStatementOptionValue(attributes);
    getExperianQuickSearchSource(attributes);
  },
  { immediate: true }
);
</script>
<style scoped>
svg.warning-icon {
  margin-bottom: 0;
}
</style>
